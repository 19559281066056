<template>
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">分销管理</a>
                    <i>></i>
                    <a
                        href="javascript:;"
                        class="cur-a"
                    >分销订单</a>
                </span>
            </div>
            <div class="framePage-body">
                <div class="operationControl">
                    <div class="searchbox">
                        <div
                            title="订单号"
                            class="searchboxItem"
                        >
                            <span class="itemLabel">订单号:</span>
                            <el-input
                                v-model="orderSn"
                                type="text"
                                size="small"
                                style="width:auto"
                                placeholder="请输入订单号"
                                clearable
                            />
                        </div>
                        <div
                            title="买家"
                            class="searchboxItem"
                        >
                            <span class="itemLabel">买家:</span>
                            <el-input
                                v-model="lowerMobile"
                                type="text"
                                size="small"
                                style="width:auto"
                                placeholder="请输入买家"
                                clearable
                            />
                        </div>
                        <div
                            title="上级推荐会员"
                            class="searchboxItem"
                        >
                            <span class="itemLabel">上级推荐会员:</span>
                            <el-input
                                v-model="distributeMobile"
                                type="text"
                                size="small"
                                style="width:auto"
                                placeholder="请输入上级推荐会员"
                                clearable
                            />
                        </div>
                        <div>
                            <el-button
                                class="bgc-bv"
                                @click="getData1()"
                            >查询</el-button>
                        </div>
                    </div>
                </div>
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table
                            ref="multipleTable"
                            :data="tableData"
                            :height="tableHeight"
                            size="small"
                            tooltip-effect="dark"
                            style="width: 100%"
                            :header-cell-style="tableHeader"
                        >
                            <el-table-column
                                label="序号"
                                align="center"
                                type="index"
                            />
                            <el-table-column
                                label="订单号"
                                align="center"
                                prop="orderSn"
                                show-overflow-tooltip
                            />
                            <el-table-column
                                label="订单类型"
                                align="center"
                                prop="productType"
                                show-overflow-tooltip
                            >
                                <template slot-scope="scope">
                                    {{ $setDictionary("WALLETTYPE", scope.row.productType || "--") }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="买家"
                                align="center"
                                prop="lowerMobile"
                            />
                            <el-table-column
                                label="订单金额（元）"
                                align="center"
                                prop="paymentMoney"
                            />
                            <el-table-column
                                label="上级推荐会员"
                                align="center"
                                prop="distributeMobile"
                            />
                            <el-table-column
                                label="佣金（元）"
                                align="center"
                                prop="brokerage"
                            />
                            <el-table-column
                                label="支付方式"
                                align="center"
                                prop="paymentMethod"
                            >
                                <template slot-scope="scope">
                                    {{ $setDictionary('ORDER_METHOD',scope.row.paymentMethod)}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="支付时间"
                                align="center"
                                prop="payTime"
                            />
                            <el-table-column
                                label="状态"
                                align="center"
                                prop="orderStatus"
                            >
								<template slot-scope="scope">
                                    {{ scope.row.orderStatus?'已完成':'未完成'}}
                                </template>
							</el-table-column>
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>
                <PageNum
                    :apiData="apiData"
                    @sizeChange="sizeChange"
                    @getData="getData1"
                />
            </div>
        </div>
    </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
    name: "retailOrderList",
    components: {
        Empty,
        PageNum,
    },
    mixins: [List],
    data() {
        return {
            distributeMobile: "",
            lowerMobile: "",
            orderSn: "",
        };
    },
    computed: {},
    created() {
        if (this.$route.query.stu) {
            this.distributeMobile = this.$route.query.distributeMobile;
        }
        this.getData1();
    },
    methods: {
        getData1(pageNum = 1) {
            const params = {
                pageNum: pageNum,
                pageSize: this.pageSize,
                lowerMobile: this.lowerMobile,
                distributeMobile: this.distributeMobile,
                orderSn: this.orderSn,
            };
            this.doFetch(
                {
                    url: "/biz/distribute/distributor/orderList",
                    params,
                    pageNum,
                },
                true,
                2
            );
        },
    },
};
</script>
<style lang="less"></style>
